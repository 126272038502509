import React, { useState } from 'react';
import { LinkProps } from 'next/link';
import classNames from 'classnames';
import ThorDefault from './ThorDefault';
import ThorPride from './ThorPride';
import ThorHalloween from './ThorHalloween';
import ThorChristmas from './ThorChristmas';
import styles from './styles.module.scss';
export interface LinkComponentProps extends LinkProps {
  className: string;
  onMouseEnter: React.MouseEventHandler<HTMLAnchorElement>;
  onMouseLeave: React.MouseEventHandler<HTMLAnchorElement>;
  onFocus: React.FocusEventHandler<HTMLAnchorElement>;
  onBlur: React.FocusEventHandler<HTMLAnchorElement>;
  children: React.ReactNode;
}
export interface LogoProps extends LinkProps {
  className?: string;
  renderLinkComponent: (props: LinkComponentProps) => JSX.Element;
}
const getLogo = ({
  isWinking,
  isJune,
  isHalloween,
  isDecember
}: {
  isWinking: boolean;
  isJune: boolean;
  isHalloween: boolean;
  isDecember: boolean;
}) => {
  if (isJune) return <ThorPride className={styles.icon} isWinking={isWinking} data-testid="thor-pride" />;
  if (isHalloween) return <ThorHalloween className={styles.icon} isWinking={isWinking} data-testid="thor-halloween" />;
  if (isDecember) {
    return <ThorChristmas className={styles.icon} isWinking={isWinking} data-testid="thor-christmas" />;
  }
  return <ThorDefault className={styles.icon} isWinking={isWinking} data-testid="thor" data-sentry-element="ThorDefault" data-sentry-component="getLogo" data-sentry-source-file="index.tsx" />;
};
const Logo = ({
  className,
  renderLinkComponent,
  ...props
}: LogoProps) => {
  const [isWinking, setIsWinking] = useState(false);
  const handleEnter = () => setIsWinking(true);
  const handleLeave = () => setIsWinking(false);
  const month = new Date().getMonth();
  const date = new Date().getDate();
  const isJune = month === 5;
  const isHalloween = month === 9 && date >= 17 && date <= 31;
  const isDecember = month === 11;
  return renderLinkComponent({
    className: classNames(styles.logo, {
      [styles.logoChristmas]: isDecember
    }, className),
    onMouseEnter: handleEnter,
    onMouseLeave: handleLeave,
    onFocus: handleEnter,
    onBlur: handleLeave,
    children: <>
        {getLogo({
        isWinking,
        isJune,
        isHalloween,
        isDecember
      })}
        <span className={styles.text}>Accommodation for Students</span>
      </>,
    ...props
  });
};
export default Logo;