import React from 'react';
import Link from 'next/link';
import { homepageLink } from '../../../contexts/countryContext';
import Logo from '../../atoms/Logo';
import PrimaryNavigation from '../../molecules/PrimaryNavigation';
import BurgerButton from '../../molecules/BurgerButton';
import MobileMenu from '../../organisms/MobileMenu';
import './styles.scss';
interface HeaderProps extends React.HTMLProps<HTMLDivElement> {
  showNavigation?: boolean;
}
const MobileMenuButton = (props: {
  onClick: () => void;
}) => <BurgerButton className="site-header__burger" {...props} data-sentry-element="BurgerButton" data-sentry-component="MobileMenuButton" data-sentry-source-file="index.tsx" />;
const Header = ({
  showNavigation = true,
  ...props
}: HeaderProps) => {
  return <header className="site-header" {...props} data-sentry-component="Header" data-sentry-source-file="index.tsx">
      <div className="site-header__inner">
        <Logo className="site-header__logo" href={homepageLink()} renderLinkComponent={props => <Link {...props} />} data-sentry-element="Logo" data-sentry-source-file="index.tsx" />

        {showNavigation && <>
            <PrimaryNavigation className="site-header__nav" />
            <MobileMenu openButton={MobileMenuButton} />
          </>}
      </div>
    </header>;
};
export default Header;