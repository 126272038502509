import React, { ReactNode } from 'react';
import NextLink from 'next/link';
import classNames from 'classnames';
import styles from './styles.module.scss';
export interface LinkProps {
  className?: string;
  to?: string;
  variant?: 'alpha' | 'grey';
  children?: ReactNode;
  [key: string]: any;
}
const Link: React.FC<LinkProps> = ({
  className,
  children,
  to,
  variant = 'alpha',
  ...props
}) => {
  const componentClasses = classNames(styles.link, {
    [styles.linkGrey]: variant === 'grey'
  }, className);
  if (to) {
    return <NextLink className={componentClasses} href={to} {...props}>
        {children}
      </NextLink>;
  }
  return <a className={componentClasses} {...props} data-sentry-component="Link" data-sentry-source-file="index.tsx">
      {children}
    </a>;
};
export default Link;