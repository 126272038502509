import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Transition from 'react-transition-group/Transition'

import useBodyLockWhenOverlayIsActive from '../../../hooks/useBodyLockWhenOverlayIsActive'

import Icon from '../../atoms/Icon'
import Portal from '../../atoms/Portal'

import './styles.scss'

const Overlay = ({ children, openButton: OpenButton }) => {
  const [isActive, setIsActive] = useState(false)

  useBodyLockWhenOverlayIsActive(isActive, 'no-scroll')

  const handleOpenClick = () => {
    setIsActive(true)
  }

  const handleCloseClick = () => {
    setIsActive(false)
  }

  const renderChildren = () => {
    if (typeof children === 'function') {
      return children(handleCloseClick)
    }

    return children
  }

  return (
    <>
      <OpenButton onClick={handleOpenClick} />
      <Portal>
        <Transition in={isActive} timeout={50} mountOnEnter unmountOnExit>
          {(status) => (
            <div key="0" className={`overlay overlay--${status}`}>
              <header className="overlay__header">
                <button
                  className="overlay__close"
                  onClick={handleCloseClick}
                  type="button"
                >
                  <Icon
                    className="overlay__close__icon"
                    icon="cross"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    transform="translate(2 2)"
                  />
                  Close
                </button>
              </header>
              <div className="overlay__content">{renderChildren()}</div>
            </div>
          )}
        </Transition>
      </Portal>
    </>
  )
}

Overlay.propTypes = {
  openButton: PropTypes.any.isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
}

export default Overlay
