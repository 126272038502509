import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'

const Portal = ({ children, node }) => {
  const defaultNode = useRef(null)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    if (!node) {
      defaultNode.current = document.createElement('div')

      document.body.appendChild(defaultNode.current)
    }

    setMounted(true)

    return () => {
      if (!node) {
        document.body.removeChild(defaultNode.current)

        defaultNode.current = null
      }
    }
  }, [])

  const target = node || defaultNode.current

  return mounted ? createPortal(children, target) : null
}

Portal.propTypes = {
  node: PropTypes.node,
  children: PropTypes.node,
}

export default Portal
