import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Link from '../../../atoms/Link'
import Button from '../../../atoms/Button'

import './styles.scss'

const PrimaryNavigationUK = ({ className }) => {
  const navClasses = classNames('primary-nav', className)

  return (
    <nav className={navClasses} aria-label="Primary navigation">
      <ul className="primary-nav__list">
        <li className="primary-nav__item">
          <Link className="primary-nav__link" to="/student-guides">
            Guides
          </Link>
        </li>
        <li className="primary-nav__item">
          <Link className="primary-nav__link" to="/student-blog">
            Blog
          </Link>
        </li>
        <li className="primary-nav__item">
          <Link className="primary-nav__link" to="/utilities">
            Utilities
          </Link>
        </li>
        <li className="primary-nav__item">
          <Link className="primary-nav__link" to="/advertise">
            Advertise
          </Link>
        </li>
        <li className="primary-nav__item primary-nav__item--login">
          <Button href="/login" secondary>
            Landlords / Agents
          </Button>
        </li>
      </ul>
    </nav>
  )
}

PrimaryNavigationUK.propTypes = {
  className: PropTypes.string,
}

export default PrimaryNavigationUK
