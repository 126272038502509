import React from 'react'
import PropTypes from 'prop-types'

import { useCountryContext } from '../../../contexts/countryContext'

import PrimaryNavigationUK from './PrimaryNavigationUk'
import PrimaryNavigationInternational from './PrimaryNavigationInternational'

const PrimaryNavigation = ({ className }) => {
  const { isInternational } = useCountryContext()

  return isInternational ? (
    <PrimaryNavigationInternational className={className} />
  ) : (
    <PrimaryNavigationUK className={className} />
  )
}

PrimaryNavigation.propTypes = {
  className: PropTypes.string,
}

export default PrimaryNavigation
